/* face section  *

/* Default styles */
.fact-container {
    /* background-color: #d6eac7; */
    background-color: #696868;
    padding: 2.5rem 2rem;
    /* background: rgb(0, 15, 38); */
    /* background: rgb(4,2,101);
background: linear-gradient(90deg, rgba(4,2,101,1) 0%, rgba(19,34,158,1) 35%, rgba(14,40,226,1) 100%); */

/* background: rgb(126,4,4);
background: linear-gradient(90deg, rgba(126,4,4,1) 0%, rgba(190,0,0,1) 35%, rgba(255,59,59,1) 100%); */
color: #000;
  }
  
  .fact-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .fact-item {
    flex: 0 0 23%;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .counter {
    display: flex;
  }
  
  .counter-value {
    font-size: 2rem;
  }
  
  .text-primary {
    color: #000;
  }
  
  .text-white {
    color: #fff;
    /* font-size: 20px; */
  }
  
  /* Media queries for responsiveness */
  @media screen and (max-width: 1200px) {
    .fact-item {
      flex: 0 0 30%;
    }
  }
  
  @media screen and (max-width: 992px) {
    .fact-item {
      flex: 0 0 45%;
    }
  
    .tagline{ 
      margin-top: 30px;
    }
  
  }
  
  @media screen and (max-width: 768px) {
    .fact-item {
      flex: 0 0 100%;
    }
  
    
}

.counter {
    display: flex;
    align-items: center; /* Vertically aligns h1 and h5 */
  }
  
  .counter h1 {
    font-size: 2.5rem; /* Adjust size to fit visually with h5 */
    margin-right: 15px; /* Space between h1 and h5 */
  }
  
  .counter h5 {
    font-size: 1.2rem; /* Adjust size for balance */
    margin: 0;
    line-height: 1.5; /* Adjust line height if needed */
  }
  
  @media (max-width: 768px) {
    .counter h1 {
      font-size: 2rem; /* Adjustments for smaller screens */
    }
    .counter h5 {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .counter h1 {
      font-size: 1.8rem;
    }
    .counter h5 {
      font-size: 0.9rem;
    }
  }
  