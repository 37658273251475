.scroll-container {
  overflow: hidden;
  width: 100%;
  height: 300px; /* Adjust the height according to your images */
  position: relative;
}

.scroll-content {
  display: flex;
  animation: scroll 20s linear infinite; /* Adjust duration as needed */
  width: 200%; /* Width is doubled due to image duplication */
}

.scroll-image {
  flex-shrink: 0;
  width: 350px; /* Default width for large screens */
  height: 100%;
  margin-right: 20px; /* Gap between images */
}

.scroll-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

/* Animation for continuous scrolling */
@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Media Queries */

/* Medium screens (tablets) */
@media (max-width: 768px) {
  .scroll-container {
    height: 150px;
  }

  .scroll-image {
    width: 200px; /* Adjust width for tablets */
    margin-right: 15px; /* Adjust gap for tablets */
  }
}

/* Small screens (phones) */
@media (max-width: 480px) {
  .scroll-container {
    height: 220px;
  }

  .scroll-image {
    width: 250px; /* Adjust width for small screens */
    margin-right: 10px; /* Adjust gap for small screens */
  }

  .scroll-content {
    display: flex;
    animation: scroll 20s linear infinite;
    width: 200%;
}

}
