
  
  
  /* General Section Styling */
.section-mainbox {
  /* margin: 2rem 0; */
  padding: 1rem 2rem;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

/* Headings */
.section-mainbox h2 {
  /* font-size: 2rem; */
  color: rgba(190,0,0,1);
  margin-bottom: 1rem;
  border-bottom: 2px solid rgba(190,0,0,1);
  padding-bottom: 0.5rem;
}

/* Paragraphs */
.section-mainbox p {
  /* font-size: 1rem; */
  color: #333;
  line-height: 1.6;
  margin-bottom: 1rem;
}

/* Unordered Lists */
.section-mainbox ul {
  list-style-type: disc;
  padding-left: 2rem;
  margin-bottom: 1rem;
}

.section-mainbox ul li {
  margin-bottom: 0.5rem;
  /* font-size: 1rem; */
  color: #555;
}

/* Emphasis */
.section-mainbox em {
  font-style: italic;
  color: rgba(190,0,0,1);
}

/* Strong Emphasis */
.section-mainbox strong {
  font-weight: bold;
  color: rgba(190,0,0,1);
}


@media (min-width: 480px) {
  .section-mainbox {
    width: 90%;
  }
}

@media (min-width: 320px) {
  .section-mainbox {
    width: 90%;
  }
}