/* navbar */

.Nav-logo {
    width: 150px;
    height: auto;
    filter: drop-shadow(5px 5px 5px #fff); 
  }
  
  .MuiAppBar-colorPrimary {
    color: #fff;
    background-color: #a4d09ccd !important;
  }
  
  .navbar {
    background: #7e0404;
    background: linear-gradient(90deg, #7e0404, #be0000 35%, #ff3b3b);
  }
  
  .each-slide-effect>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .makeStyles-link-3{
    color: #fff !important;
    font-weight: 700;
  }

  .makeStyles-link-3:hover{
    color: #000 !important;
    font-weight: 700;
  }

  .MuiDrawer-paper{
    background-color: #a4d09c !important;
  }