@import url("https://fonts.googleapis.com/css2?family=Baskervville+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baskervville+SC&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

body {
  background-image: url("./Pages/images/bodybg1.png");
  background-size: cover; /* Scale the image to cover the entire body */
  background-position: center; /* Center the image in the viewport */
  background-repeat: no-repeat; /* Prevent the image from repeating */
}

h1, h2, h3,h4,h5 {
  font-family: "Baskervville SC", serif;
  font-weight: 900;
  font-style: normal;
  color: #d6291e;
}

h2{
  color: #d6291e;
}

p {
  font-family: "Merriweather", serif;
  font-weight: 500;
  font-style: normal;
  text-align: justify;
}



li::marker {
  content: "👩🏻‍🎓";
  /* 👩🏻‍🎓˙✧˖°🎓 ༘⋆｡ ˚ */
  font-size: 18px;
  color: #d6291e;
  /* position: absolute; */
  /* left: 0;  */
  /* top: 50%;  */
  /* transform: translateY(-50%); */
}

.box-title2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #d6291e;
  margin-top: 25PX;
  text-align: center;
}

.page-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

/* Media Query for screens 320px and up */
@media (min-width: 320px) {
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }
}

/* Media Query for screens 480px and up */
@media (min-width: 480px) {
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  p {
    font-size: 10px;
  }
}

/* Media Query for screens 768px and up (Tablets) */
@media (min-width: 768px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 20px;
  }
  p {
    font-size: 12px;
  }
}

/* Media Query for screens 1024px and up (Small Laptops) */
@media (min-width: 1024px) {
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 28px;
  }
  h5 {
    /* font-size: 20px; */
  }
  p {
    font-size: 16px;
  }
}

/* Media Query for screens 1200px and up (Laptops and Desktops) */
/* @media (min-width: 1200px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 34px;
  }
  h4 {
    font-size: 32px;
  }
  h5 {
    font-size: 28px;
  }
  p {
    font-size: 20px;
  }
} */

.hr-01{
  width: 80%;
  margin: auto;
}

.heading-h1 {
  margin: auto;
  text-align: center;
  justify-content: center;
  /* font-size: 35px; */
  color: #d6291e;
  font-family: "Baskervville SC", serif;
  font-weight: 900;
  font-style: normal;
  /* text-align: justify; */
}

.sub-heading {
  color: #d6291e;
  font-family: "Baskervville SC", serif;
  font-weight: 800;
  font-style: normal;
  text-align: justify;
}

/* homw page start */
/* GDGoenkaSchool.css */

.gdg-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-bottom: 50px;
  /* background-image: url('./components/images/bggg111.jpg'); */
}

.gdg-box {
  flex: 1 1 calc(50% - 20px);

  padding: 10px;
  border-radius: 15px;
}

.gdg-box1 p {
  /* font-size: 16px; */
  line-height: 1.6;
  color: #000;
}

.gdg-box2 img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .gdg-box {
    flex: 1 1 100%;
  }
}

/* home page end */

/* about us page start */

/* Style for the main container */
.why-goenka-container {
  /* max-width: 800px; */
  width: 70%;
  margin: 0 auto;
  padding: 20px;
}

/* Style for each section */
.section-1 {
  margin-bottom: 20px;
}

.section-2 {
  margin-bottom: 20px;
}

.section-3 {
  margin-bottom: 20px;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .why-goenka-container {
    padding: 10px;
    width: 85%;
  }
}

/* about us end */

/* about start  */

/* Section.css */

/* Base styles for Section */
.about-bb-section {
  padding-top: 100px;
  /* background-image: url('../src/Pages/images/aboutbg11.png'); */
  background-repeat: no-repeat;
  background-position: center top;
}

.about-bb-section_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 451.688px;
  width: 100%;
}

.about-bb-wrap {
  display: flex;
  align-items: center;
  height: 100%;
}

.about-bb-two-fifth {
  width: 40%;
  margin: auto;
  text-align: center;
  justify-content: center;
}

.about-bb-three-fifth {
  width: 60%;
}

.wrapper h3 {
  color: #d6291e;
}

.about-bb-wrap-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
}

.about-bb-column_column {
  text-align: center;
  padding: 40px 0 0;
}

.about-bb-heading-main {
  color: #000;
  margin-bottom: 0;
}

.about-bb-heading-sub {
  margin-top: 0;
}

/* Image styles */
.about-bb-image_frame {
  text-align: center;
}

.about-bb-image_wrapper {
  display: inline-block;
}

.about-bb-image {
  max-width: 100%;
  height: auto;
}

/* Media queries */
@media (max-width: 768px) {
  .about-bb-section {
    padding-top: 50px;
  }

  .about-bb-section_wrapper {
    flex-direction: column;
    height: auto;
  }

  .about-bb-two-fifth,
  .about-bb-three-fifth {
    width: 100%;
  }
}

/* about end */

/* contact start  */

/* .contact-main-box {
  width: 70%;
  margin: auto;
  margin-bottom: 100px;
  background: rgb(126, 4, 4);
  background: linear-gradient(
    90deg,
    rgba(126, 4, 4, 1) 0%,
    rgba(190, 0, 0, 1) 35%,
    rgba(255, 59, 59, 1) 100%
  );
}

.MuiGrid-container {
  width: 80% !important;
}
.contact-container {
  width: 80%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 50px;
  color: #fff;
  text-align: center;
  background: rgb(255, 154, 47);
}

.contact-details {
  padding-top: 10px;
  padding-bottom: 10px;
} */

/* contact end  */

/* contact us start */

.contact-cc-container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
}

.contact-cc-container h1, .contact-cc-container h2, .contact-cc-container h3, .contact-cc-container h4, .contact-cc-container h5{
  color: #d6291e;
}

.contact-cc-box {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 45%; /* Ensures boxes take up equal space */
  min-width: 300px;
}

.contact-cc-box1,
.contact-cc-box2 {
  box-sizing: border-box;
}

.contact-cc-box2 {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex-direction: column; /* Stack items vertically */
  min-width: 300px;
  flex-basis: 40%;
}

.form-mainb {
  width: 100%;
  max-width: 500px; /* Optional: Set a max width for the form */
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}


.contact-cc-inboxs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.contact-cc-inboxs div {
  flex: 1 1 45%; /* Equal width for each column */
  margin: 5px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
}

.contact-cc-form-group {
  margin-bottom: 15px;
}

.contact-cc-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-cc-form-group input,
.contact-cc-form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-cc-submit-button {
  background-color: #d6291e;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.contact-cc-submit-button:hover {
  background-color: #0056b3;
}

@media (max-width: 767px) {
  .contact-cc-container {
    flex-direction: column;
  }

  .contact-cc-box {
    flex: 1 1 100%;
  }

  .contact-cc-inboxs {
    display: block; /* Stack items vertically on small screens */
  }

  .contact-cc-inboxs div {
    flex: 1 1 100%;
  }
}
@media (max-width: 320px) {
.contact-cc-box {
margin: auto;
}
}


.contact-cc-button {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  /* background-color: #d6291e old red ; */
  background-color: #d6291e;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 10px;
}



/* contact us end */

/* home page youtube video start */

.img-container {
  display: flex;
  width: 80%;
  margin: auto;
  align-items: center;
  justify-content: center;
margin-bottom: 10px;
}

.img-container img{
  width: 60%;
  /* height: 100%; */
  margin: auto;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .img-container img{
    width: 90%;
    
  }
}

/* home page youtube video end */

.banner {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.banner img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
  display: block;
}

/* Media Queries */
@media (min-width: 768px) {
  .banner {
    height: 400px; /* Adjust height for medium screens */
  }
}

@media (min-width: 1024px) {
  .banner {
    height: 500px; /* Adjust height for large screens */
  }
}

/* faqs start */

.faq-section {
  padding: 20px;
  background-color: transparent;
  max-width: 900px;
  margin: 0 auto;
}

.faq-section h2 {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
  color: #d6291e;
}

.faq-list {
  list-style-type: none;
  padding: 0;
}

.faq-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.faq-question {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #d6291e;
}

.faq-answer {
  font-size: 1em;
  color: #555;
}

/* Media Queries */
@media (min-width: 768px) {
  .faq-section {
    padding: 30px;
  }

  .faq-section h2 {
    font-size: 2.5em;
  }

  .faq-item {
    margin-bottom: 25px;
    padding-bottom: 15px;
  }

  .faq-question {
    font-size: 1.4em;
  }

  .faq-answer {
    font-size: 1.1em;
  }
}

@media (min-width: 1024px) {
  .faq-section {
    padding: 40px;
  }

  .faq-section h2 {
    font-size: 3em;
  }

  .faq-item {
    margin-bottom: 30px;
    padding-bottom: 20px;
  }

  .faq-question {
    font-size: 1.6em;
  }

  .faq-answer {
    font-size: 1.2em;
  }
}

/* faqs end */

/* tearcher page start */

.school-management-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  font-family: Arial, sans-serif;
}


.management-profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin: auto;
  text-align: center;
  justify-content: center;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 20px;
  box-shadow: #4a606e 0px 5px 15px;
  /* box-shadow: #d6291e 0px 5px 15px; */
}

.profile-info {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .management-profile {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-image {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* tearcher page end */ 


/* boxes containers start */

.boxes-container {
  display: flex;
  justify-content: space-between;
  margin: 20px; /* Adjust margin as needed */
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  gap: 20px;
  
}

.box {
  width: 40%; 
  /* height: 350px; */
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 15px;
  /* box-shadow: #4a606e 0px 5px 15px; */
}

.box1 {
  /* Specific styling for Box 1 */
}

.box2 {
  /* Specific styling for Box 2 */
  text-align: left;
}

.box-image {
  max-width: 100%;
  height: 100%;
  border-radius: 15px;
}

.box-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #d6291e;
  text-transform: uppercase;
}

.box-title2 {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #d6291e;
  margin-top: 25PX;
}


.box-text {
  font-size: 16px;
  line-height: 1.6;
}

/* Media Queries for Responsive Design */

@media (max-width: 1024px) {
  .boxes-container {
    flex-direction: column; /* Stack boxes vertically on medium screens */
  }

  .box {
      width: 90%; 
      height: 100%;
      padding: 20px;
    }
}

@media (max-width: 768px) {
  .box {
      width: 90%; 
      height: 100%;
      padding: 20px;
    }
}

@media (max-width: 480px) {

  .box {
      width: 90%; 
      height: 100%;
      padding: 20px;
    }

    .box-image {
      width: 100%;
      height: 60%;
      border-radius: 15px;
    }

}


/* boxes containers end */



/* Gallery.css start */

.gallery-container {
  padding: 20px;
  width: 80%;
  margin: auto;
  justify-content: center;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.gallery-item {
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gallery-image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.gallery-item:hover .gallery-image {
  transform: scale(1.1);
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.lightbox-image {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
}

.lightbox-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: 5px;
}

.lightbox-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #d6291e;
  color: #ffffff;
  border-radius: 20px;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.lightbox-prev {
  left: 20px;
}

.lightbox-next {
  right: 20px;
}

.video-section {
  margin-top: 40px;
  padding: 20px;
}

.video-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  
}

.video-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.video-item iframe {
  width: 100%;
  height: 200px;
  border: none;
}


/* Extra small screens (phones) */
@media (min-width: 320px) and (max-width: 480px) {
  .video-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    margin: auto;
    text-align: center;
}

.video-grid {
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  margin: auto;
    text-align: center;
    justify-content: center;
    
}

.lightbox-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #d6291e;
  color: #ffffff;
  border-radius: 15px;
  border: none;
  padding: 7px;
  cursor: pointer;
  font-size: 6px;
}

.box-title2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #d6291e;
  margin-top: 25PX;
}

}

@media (min-width: 425px) and (max-width: 599px) {
  .video-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.video-grid {
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

}


@media (min-width: 481px) and (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .box-title2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #d6291e;
    margin-top: 25PX;
  }

  .lightbox-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #d6291e;
    color: #ffffff;
    border-radius: 15px;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
  }

  .video-grid {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    height: 500px;
        width: 50%;
        margin: auto;
  }

  .video-item iframe {
    height: 100%;
  }

  .lightbox-image {
    max-width: 90%;
    max-height: 60%;
    margin-top: 80px;
  }

  .lightbox-close {
    font-size: 1.5rem;
  }

  .video-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

}

/* Medium screens (small laptops) */
@media (min-width: 769px) and (max-width: 1024px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .box-title2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #d6291e;
    margin-top: 25PX;
  }

  .video-grid {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }

  .video-item iframe {
    height: 100%;
  }

  
  .lightbox-image {
    max-width: 85%;
    max-height: 75%;
    margin-top: 120px;
  }

  

}

/* Large screens (desktops and up) */
@media (min-width: 1025px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .video-grid {
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    height: 200px;
  }

  .video-item iframe {
    height: 100%;
  }

  .lightbox-image {
    max-width: 90%;
    max-height: 80%;
  }
}



/* gallery end */

/* news css start */

.gd-news--container {
  padding: 20px;
  /* max-width: 1200px; */
  margin: auto;
  width: 80%;
}

.gd-news--title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 30px;
}

.gd-news--section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.gd-news--event {
  flex: 1;
  padding: 20px;
  background-color: transparent;
  border: 0.5px solid #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  /* margin: auto; */
  /* width: 100%; */
}

.gd-news--event-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #d6291e;
}

.gd-news--event ul {
  margin-left: 20px;
}

.gd-news--event p {
  margin-bottom: 10px;
  font-size: 1rem;
}

.gd-newsimg{
  margin: auto;
  text-align: center;
}

.gd-news--event img{
  width: 50%;
  margin: auto;
  text-align: center;
  border-radius: 15px;
}

.readbtn{
  background: #d6291e;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid black;
  font-weight: 700;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .gd-news--section {
      flex-direction: column;
  }

  .gd-news--event {
      margin-bottom: 20px;
  }

  .gd-news--title {
      font-size: 1.8rem;
  }

  .gd-news--event-title {
      font-size: 1.3rem;
  }
}

@media (min-width: 769px) {
  .gd-news--event {
      flex-basis: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .gd-news--title {
      font-size: 1.5rem;
  }

  .gd-news--event-title {
      font-size: 1.2rem;
  }

  .gd-news--event {
    
    width: 100%;
  }

  .gd-news--event img{
    width: 80%;
    margin: auto;
    text-align: center;
    border-radius: 15px;
  }

  .gd-news--container {
   
    width: 95%;
}

.linkk-news{
  font-size: 10px;
  text-overflow: clip;
}

}

/* news css end */

/* houses start */

.houses-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
}

.house-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
 
}

.house-box:hover {
  transform: scale(1.05);
}

.house-img {
  width: 100%;
  
  height: auto;
  border-radius: 8px;
}

.house-heading {
  margin-top: 10px;
  font-size: 1.5rem;
  text-align: center;
  color: #333;
}

@media (max-width: 768px) {
  .houses-container {
    flex-direction: column;
    align-items: center;
  }

  .house-box {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .house-heading {
    font-size: 1.2rem;
  }
}

.css-q6ybee-MuiImageList-root {
  width: 80% !important;
  margin-bottom: 50px !important;
}

/* houses end */

/* clubs start */

.clubs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.club-card {
  flex: 1 1 calc(33.333% - 40px); /* 3 cards per row on larger screens */
  max-width: 300px;
  background: #f5f5f588;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 15px;
  transition: transform 0.3s;
}

.club-card:hover {
  transform: translateY(-5px);
}

.club-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.club-name {
  font-size: 1.2rem;
  color: #333;
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .club-card {
    flex: 1 1 calc(50% - 40px); /* 2 cards per row on medium screens */
  }
}

@media (max-width: 768px) {
  .club-card {
    flex: 1 1 100%; /* 1 card per row on small screens */
  }
}

/* clubs end */
