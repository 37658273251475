/* 
  
  .more-container {
    padding: 20px;
    text-align: center;
  }
  
  .heading {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .links-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .link-box {
    text-decoration: none;
    color: #000;
    background-color: rgba(255, 255, 255, 0.7); 
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 250px; 
  }
  
  .link-box p {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .link-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  
  @media (max-width: 768px) {
    .heading {
      font-size: 1.5rem;
    }
  
    .link-box {
      font-size: 1rem;
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .heading {
      font-size: 1.2rem;
    }
  
    .link-box {
      font-size: 0.9rem;
      padding: 8px;
    }
  }
   */

   /* more stat */

  .more-boxes-container {
    display: grid;
    width: 80%;
    margin: auto;
    gap: 20px;
    padding: 20px;
    grid-template-columns: repeat(4, 1fr); 
    
  }
  
  .more-box {
    align-items: center;
    background-color: rgba(255, 0, 0, .063);
    border: .5px solid rgba(245, 0, 0, .416);
    border-radius: 15px;
    box-shadow: 0 5px 15px hsla(0, 0%, 87%, .874);
    color: inherit;
    display: flex
;
    flex-direction: column;
    height: 250px;
    overflow: hidden;
    text-decoration: none;
    transition: transform .3s;
  }

  .more-box h1{
    color: #000;
    margin: auto;
  }
  
  .more-box:hover {
    transform: scale(1.05);
  }
  
  .more-box-image {
    width: 100%;
    height: auto;
  }
  
  .more-box-title {
    margin: 10px 0;
    font-size: 1.2rem;
    text-align: center;
  }
  
  /* Medium screens (tablets) */
  @media (max-width: 1024px) {
    .more-boxes-container {
      grid-template-columns: repeat(3, 1fr); /* 3 boxes per row for medium screens */
    }

    .more-box 
    {
      height: 200px;
    }
  }
  
  /* Small screens (mobile) */
  @media (max-width: 600px) {
    .more-boxes-container {
      grid-template-columns: 1fr; /* 1 box per row for small screens */
    }
  }
  
  
  /* more end */