/* body {
    background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgAQMAAABJtOi3AAAABlBMVEX4+Pj09PQf/c7fAAAAGklEQVR4Xq3MIQEAAADDIPqX/kvMIMEWEm8O7b0/wewc/NcAAAAASUVORK5CYII=) repeat;
  } */
  
  /* .wrapper {
    padding: 2em;
  } */
  
  .block {
    /* height: 40em; */
    /* line-height: 5em; */
    /* width: auto; */
    /* background: #464646; */
    /* color: #fdfdfd; */
    /* text-align: center; */
    /* margin: 1em auto; */
    /* text-shadow: 0 0 1px #333; */
  }
  
  .animatable {
    visibility: hidden;
    animation-play-state: paused;
  }
  
  .animated {
    visibility: visible;
    animation-fill-mode: both;
    animation-duration: 1s;
    animation-play-state: running;
  }
  
  /* Add your keyframe animations */
  @keyframes fadeIn {
    0%,
    60% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(0.3);
    }
    50% {
      transform: scale(1.05);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes bounceInLeft {
    0% {
      opacity: 0;
      transform: translateX(-2000px);
    }
    60% {
      transform: translateX(20px);
    }
    80% {
      transform: translateX(-5px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes bounceInRight {
    0% {
      opacity: 0;
      transform: translateX(2000px);
    }
    60% {
      transform: translateX(-20px);
    }
    80% {
      transform: translateX(5px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes moveUp {
    0% {
      transform: translateY(40px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes fadeBgColor {
    0%,
    70% {
      background: none;
    }
    100% {
      background: #464646;
    }
  }
  
  .animated.animationDelay {
    animation-delay: 0.4s;
  }
  .animated.animationDelayMed {
    animation-delay: 1.2s;
  }
  .animated.animationDelayLong {
    animation-delay: 1.6s;
  }
  .animated.fadeBgColor {
    animation-name: fadeBgColor;
  }
  .animated.bounceIn {
    animation-name: bounceIn;
  }
  .animated.bounceInRight {
    animation-name: bounceInRight;
  }
  .animated.bounceInLeft {
    animation-name: bounceInLeft;
  }
  .animated.fadeIn {
    animation-name: fadeIn;
  }
  .animated.fadeInDown {
    animation-name: fadeInDown;
  }
  .animated.fadeInUp {
    animation-name: fadeInUp;
  }
  .animated.moveUp {
    animation-name: moveUp;
  }
  