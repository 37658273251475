* {
    box-sizing: border-box;
    margin: 0;
  }
  
  :root {
    --heights: 100vh;
    --widths: 100%;
  }
  
  .slider-container {
    height: var(--heights);
    width: var(--widths);
    position: relative;
    margin: auto;
    overflow: hidden;
  }
  
  .active {
    display: inline-block;
  }
  
  .inactive {
    display: none;
  }
  
  .slides {
    height: var(--heights);
    width: var(--widths);
    position: relative;
    background-image: url('../images/GDbglogo.jpg');
    background-repeat: no-repeat;
    background-position: 0%;
  }
  
  .slide-image {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    /* opacity: 0.9; */
  }
  
  .slide-title,
  .slide-text {
    width: 100%;
    height: 100%;
    color: white;
    font-size: 40px;
    position: absolute;
    text-align: center;
    top: 30%;
    z-index: 10;
  }
  
  .slide-text {
    /* top: 65%; */
    font-size: 16px;
    width: 100%;
    height: 100%;
    color: #fff;
    text-shadow: 2px 2px #D6291E;
    position: absolute;
    text-align: center;
    top: 35%;
    z-index: 10;
    padding: 150px;
    
  }
  
  .prev,
  .next {
    color: transparent;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 0.8rem;
    margin-top: -3rem;
    font-size: 38px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: #fff;
    background: #89b482;

    background: #7e0404;
    background: linear-gradient(90deg, #7e0404, #be0000 35%, #ff3b3b);
    
  }

  /* For screens 480px and above */
  @media (min-width: 320px) and (max-width: 480px) {
  .prev,
  .next {
    font-size: 30px;
    padding: 0.3rem;
  }
}






  
  .slider-container:hover .prev,.slider-container:hover .next {
    color: black
  }
  
  .slider-container:hover .prev:hover,
  .slider-container:hover .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
  
  .next {
    right: 0;
    border-radius: 5px 0px 0px 5px;
  }
  
  .all-dots {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 85%;
    justify-content: center;
    z-index: 200;
  }
  
  .dot {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0px 3px;
    background-color: transparent;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border-radius: 50%;
    display: inline-block;
  }
  
  .slider-container:hover .dot:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .slider-container:hover .dot{
    background-color: rgba(0, 0, 0, 0.3);
  }
  .slider-container:hover .active-dot{
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .play-pause {
    color: black;
  }
  
  
  /* Media queries for smaller screen sizes */
  @media only screen and (max-width: 768px) {
    .slide-text {
      padding: 50px; 
      top: 40%;
    }
  }
  
  /* Media queries for even smaller screen sizes */
  @media only screen and (max-width: 480px) {
    .slide-title {
      font-size: 30px; 
    }
  
    .slide-text {
      padding: 50px; 
      top: 45%;
      font-size: 13px;
    }
  
  }
  
  @media only screen and (max-width: 320px) {
    .slide-text {
      padding: 50px; 
      top: 45%;
      font-size: 12px;
    }
  }