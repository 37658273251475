.footer {
    background-color: #f7f7f7;
    padding: 60px 0;
    /* background: rgb(4,2,101);
background: linear-gradient(90deg, rgba(4,2,101,1) 0%, rgba(19,34,158,1) 35%, rgba(14,40,226,1) 100%); */
/* background: rgb(126,4,4);
background: linear-gradient(90deg, rgba(126,4,4,1) 0%, rgba(190,0,0,1) 35%, rgba(255,59,59,1) 100%); */

background: #7e0404;
    background: linear-gradient(90deg, #7e0404, #be0000 35%, #ff3b3b);

/* background: #89b482dc; */

}
  
  .widgets-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    text-align: center;
    width: 80%;
  }
  
  .Footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  
  .Footer-column {
    padding: 10px;
    /* margin: auto; */
    /* text-align: center; */
  }

  .one{
    margin: auto;
    text-align: center;
  }
  
  
  .one-fourth-footer {
    flex: 1 1 22%;
  }
  
  .widget_text h4 {
    font-size: 18px;
    margin-bottom: 20px;
    color: #000;
  }
  
  .custom-html-widget ul {
    list-style: none;
    padding: 0;
  }
  
  .custom-html-widget ul li {
    margin-bottom: 10px;
    text-align: left;
  }
  
  .arrow {
    color: #000;
    margin-right: 10px;
  }
  
  .custom-html-widget a {
    color: #fff;
    text-decoration: none;
  }
  
  .contact-email {
    color: #000;
  }
  
  .footer_copy {
    /* background-color: #222; */
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer_button {
    display: inline-block;
    margin-bottom: 10px;
    color: #fff;
    text-decoration: none;
  }
  
  .footer_button i {
    font-size: 20px;
  }
  
  .copyright {
    font-size: 14px;
    margin: auto;
    text-align: center;
  }
  
  .social {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  @media screen and (max-width: 768px) {
    .one-fourth-footer {
      flex: 1 1 48%;
      margin-bottom: 20px;
    }
  
    .footer_copy .Footer-container {
      flex-direction: Footer-column;
    }
  }
  
  @media screen and (max-width: 480px) {
    .one-fourth-footer {
      flex: 1 1 100%;
    }
  }
  