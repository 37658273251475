.unique-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 90%;
    margin: auto;
  }
  
  .unique-box {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .unique-box1 {
    /* background-color: #e6f7ff; */
    /* background-image: url('../components/images/bggg111.jpg'); */
    /* color: rgba(190,0,0,1); */
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: transparent;
  }
  
  .unique-box2 {
    /* background-color: #fff7e6; */
    /* background-image: url('../components/images/bggg111.jpg'); */
    /* color: rgba(190,0,0,1); */
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: transparent;
  }
  
  @media screen and (min-width: 768px) {
    .unique-container {
      flex-direction: row;
    }
  
    .unique-box1, .unique-box2 {
      flex: 1;
      margin-right: 10px;
    }
  
    .unique-box2 {
      margin-right: 0;
    }
  }
  