.unique-menu-main-desk {
  overflow: hidden;
}

.unique-thim-nav-wrapper {
  background-color: #f8f9fa;
  padding: 10px 0;
  position: relative; /* Ensure the menu has a positioning context */
  z-index: 10; /* Ensure the menu is above other components */
}

.unique-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}

.unique-row {
  display: flex;
  flex-wrap: wrap;
}

.unique-navigation {
  padding: 0 !important;
  margin: auto;
}

.unique-width-navigation {
  display: flex;
  justify-content: center;
}

.unique-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.unique-menu-main-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.unique-thim-ekits-menu__nav {
  display: flex;
  flex-wrap: wrap;
}

.unique-menu-item {
  position: relative;
  margin: 0 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.unique-menu-item a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.unique-menu-item a:hover {
  color: #007bff;
}

.unique-icon-toggle {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  font-size: 0.8em;
}

.unique-icon-toggle i {
  color: #333;
}

.unique-menu-item:hover .unique-icon-toggle i {
  color: #007bff;
}

.unique-sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  flex-direction: column;
  width: 200px;
  z-index: 20; /* Ensure the submenu is above other components */
}

.unique-menu-item:hover .unique-sub-menu {
  display: flex;
}

.unique-sub-menu .unique-menu-item {
  margin: 0;
}

.unique-sub-menu .unique-menu-item a {
  padding: 10px 15px;
  color: #333;
  display: flex;
  align-items: center;
}

.unique-sub-menu .unique-menu-item a:hover {
  background-color: #f1f1f1;
}

.unique-menu-right {
  margin-left: auto;
}

.last-menu-item {
  border-right: none;
}

@media (max-width: 768px) {
  .unique-menu-main-desk {
      display: none;
  }
}

/* Add position and z-index to parent elements if missing */
.unique-menu-main-desk {
  overflow: hidden;
  position: relative; /* Ensure stacking context */
  z-index: 10; /* This is for the main menu, adjust as needed */
}

.unique-thim-nav-wrapper {
  background-color: #f8f9fa;
  padding: 10px 0;
  position: relative; /* Ensure stacking context */
  z-index: 20; /* Increase z-index to make sure it's above other components */
}

.unique-sub-menu {
  display: none;
  position: absolute; /* Essential for dropdown to appear correctly */
  top: 100%;
  left: 0;
  background-color: #fff;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  width: 200px;
  z-index: 1000; /* Increase this value to make sure it appears above other content */
}

.unique-menu-item:hover .unique-sub-menu {
  display: flex;
}

/* Add debug borders to see the stacking context visually */
.unique-menu-main-desk,
.unique-thim-nav-wrapper,
.unique-sub-menu {
  border: 1px solid red; /* Debugging border */
}

