.meal-plan-container {
    
    padding: 20px;
    max-width: 1200px;
    text-align: justify;
    width: 80%;
    margin: auto;
  }
  
  .age-group-title {
    font-size: 1.5rem;
    color: rgba(190,0,0,1);
    margin-bottom: 20px;
    text-align: center;
  }
  
  .meal-day {
    margin-bottom: 40px;
    text-align: justify;
  }
  
  .day-title {
    font-size: 1.25rem;
    color: rgba(190,0,0,1);
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 15px;
    text-align: justify;
  }
  
  .meal-type {
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .meal-type-title {
    font-size: 1.125rem;
    font-weight: bold;
    color: rgba(190,0,0,1);
    margin-bottom: 10px;
  }
  
  .meal-list {
    list-style-type: none;
    padding: 0;
    text-align: justify;
  }
  
  .meal-item {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #666;
    text-align: justify;
  }
  
  .meal-item strong {
    color: #333;
    text-align: justify;
  }
  
  .meal-item em {
    color: #888;
    text-align: justify;
  }
  
/* ------------------------------------------------- */
  
/* File: AgeGroups.css */
.page-title {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
  }
  
  .age-groups-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 80%;
    margin: 0 auto; /* Center the container horizontally */
  }
  
  .age-group-box {
    flex: 1;
    min-width: 200px; /* Minimum width for each box */
    padding: 100px 90px;
    background: transparent;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #000;
    border: 1px solid #fff;
    border-radius: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
    margin-bottom: 100px;
  }
  
  .age-group-box:hover {
    transform: scale(1.05); /* Slightly enlarge the box */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .age-groups-container {
      flex-direction: column;
    }

    .meal-plan-container {
    
      padding: 20px;
      max-width: 1200px;
      text-align: justify;
      width: 90%;
      margin: auto;
    }

  }

  @media (max-width: 320px) {
    

    .meal-plan-container {
    
      padding: 20px;
      max-width: 1200px;
      text-align: justify;
      width: 90%;
      margin: auto;
    }

  }
  